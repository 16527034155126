'use client';

import { ReactNode, useMemo, useRef, useState } from 'react';
import { UserSettings } from '../../../environmentVariables/EnvironmentVariables';
import {
	BOOSTED_MULITPLIER,
	TURBO_MULTIPLIER,
} from '../../../providers/priorityFees/consts';
import usePriorityFeeStore from '../../../stores/usePriorityFeeStore';
import {
	PriorityFeeCalculator,
	SANITY_CHECK_ABS_MAX_FEE_IN_SOL,
} from '../../../utils/PriorityFeeCalculator';
import Button from '../../Button';
import TextField from '../../Inputs/TextField';
import Text from '../../Text/Text';
import InfoMessage from '../../TradeForm/InfoMessage';
import { LabelAndField } from 'src/components/TradeForm/TradeFormPieces';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import { MarketId } from '@drift/common';
import MarketIcon from 'src/components/Utils/MarketIcon';

type PriorityFeeInputProps = {
	value: UserSettings['priorityFee'];
	selectedValue: UserSettings['priorityFee'];
	label: string;
	description?: string | ReactNode;
	onSelect: () => void;
	getPriorityFeeEstimateInSol: () => number;
};

const MIN_SOL_STR = '0.000000001';

const PriorityFeeInputButton = (props: PriorityFeeInputProps) => {
	const isSelected = props.selectedValue === props.value;

	return (
		<Button.BigInput onClick={props.onSelect} highlighted={isSelected}>
			<div className="flex flex-col items-start w-full space-y-1">
				<div className="flex justify-between w-full">
					<div className="flex items-center space-x-2">
						<Text.BODY2 className="text-text-emphasis">
							{props.label}
						</Text.BODY2>
					</div>
				</div>
				<div className="h-4">
					<Text.MICRO1 className="w-full text-left text-text-secondary">
						{props.description}
					</Text.MICRO1>
				</div>
			</div>
		</Button.BigInput>
	);
};

const PriorityFeeSettingsInputs = ({
	localSettings,
	setLocalSettings,
}: {
	localSettings: UserSettings;
	setLocalSettings: (setting: UserSettings) => void;
}) => {
	const initialSettingsRef = useRef(localSettings);
	const currentSettingsRef = useRef(localSettings);

	const getPriorityFeeToUse = usePriorityFeeStore((s) => s.getPriorityFeeToUse);

	const getOraclePrice = useGetOraclePriceForMarket();

	const localPriorityFeeSetting = useRef(localSettings.priorityFee);

	const localCustomPriorityFee = useRef(
		localSettings.customPriorityFee?.toString()
	);

	const localPriorityFeeMaxCap = useRef(
		localSettings?.maxPriorityFeeCap?.toString()
	);

	const [showMaxFeeMessage, setShowMaxFeeMessage] = useState(false);

	const solMarketId = MarketId.createPerpMarket(0);

	const updateSettingsValue = (updatedSettings: Partial<UserSettings>) => {
		const newSettings = {
			...currentSettingsRef.current,
			...updatedSettings,
		};

		setLocalSettings(newSettings);

		currentSettingsRef.current = newSettings;
	};

	const getPriorityFeeEstimateInSol = (
		setting: UserSettings['priorityFee']
	) => {
		return PriorityFeeCalculator.getPriorityFeeInSolForComputeUnitPrice(
			getPriorityFeeToUse(setting)
		);
	};

	const getPriorityFeeEstimateInUsd = (setting: UserSettings['priorityFee']) =>
		convertSolToUsdString(getPriorityFeeEstimateInSol(setting));

	const convertSolToUsdString = (solAmount: number) => {
		const usdAmount = solAmount * getOraclePrice(solMarketId)?.toNum();

		if (isNaN(usdAmount)) {
			return '';
		} else if (usdAmount < 0.01) {
			return '<$0.01';
		} else {
			return `~$${usdAmount.toFixed(2)}`;
		}
	};

	const priorityFeeButtonOptions: Pick<
		PriorityFeeInputProps,
		'value' | 'label' | 'description'
	>[] = useMemo(
		() => [
			{
				value: 'boosted',
				label: 'Boosted',
				description: `${BOOSTED_MULITPLIER}x`,
			},
			{
				value: 'turbo',
				label: 'Turbo',
				description: `${TURBO_MULTIPLIER}x`,
			},
			{
				value: 'custom',
				label: 'Custom',
			},
		],
		[]
	);

	const showMaxLowerThanEstMessage = useMemo(
		() =>
			localPriorityFeeSetting.current !== 'custom' &&
			parseFloat(localPriorityFeeMaxCap.current) <
				getPriorityFeeEstimateInSol(localPriorityFeeSetting.current),
		[localPriorityFeeMaxCap.current, localPriorityFeeSetting.current]
	);

	return (
		<div className="flex flex-col">
			<Text.BODY2 className="mb-1 text-text-label">Priority Fee</Text.BODY2>

			<Text.BODY3 className="mb-2 text-text-secondary">
				An extra fee added to your transactions to encourage Solana validators
				to process your transaction faster. Set a cap to prevent overpaying.
			</Text.BODY3>

			<div className="inline-flex items-center w-full space-x-2">
				<PriorityFeeInputButton
					selectedValue={localPriorityFeeSetting.current}
					value="dynamic"
					label="Dynamic"
					description={
						<div className="flex space-x-1">
							<MarketIcon sizeClass="w-4 h-4" marketSymbol="SOL" />
							<Text.MICRO1 className="mt-1 text-text-secondary">
								{getPriorityFeeEstimateInSol('dynamic')?.toFixed(5)}
							</Text.MICRO1>
						</div>
					}
					onSelect={() => {
						updateSettingsValue({
							priorityFee: 'dynamic',
							customPriorityFee: undefined,
						});
						localPriorityFeeSetting.current = 'dynamic';
						localCustomPriorityFee.current = '';

						setShowMaxFeeMessage(false);
					}}
					getPriorityFeeEstimateInSol={() =>
						getPriorityFeeEstimateInSol('dynamic')
					}
				/>

				{priorityFeeButtonOptions.map((option) => {
					return (
						<PriorityFeeInputButton
							key={option.value}
							selectedValue={localPriorityFeeSetting.current}
							{...option}
							onSelect={() => {
								if (option.value === 'custom') {
									if (initialSettingsRef.current.customPriorityFee) {
										updateSettingsValue({
											priorityFee: option.value,
											customPriorityFee:
												initialSettingsRef.current.customPriorityFee,
										});
									} else {
										updateSettingsValue({
											priorityFee: option.value,
										});
									}

									localCustomPriorityFee.current =
										initialSettingsRef.current.customPriorityFee?.toString() ??
										'';
								} else {
									updateSettingsValue({
										priorityFee: option.value,
										customPriorityFee: undefined,
									});
									localCustomPriorityFee.current = '';
								}

								localPriorityFeeSetting.current = option.value;
								setShowMaxFeeMessage(false);
							}}
							getPriorityFeeEstimateInSol={() =>
								getPriorityFeeEstimateInSol(option.value)
							}
						/>
					);
				})}
			</div>

			{localPriorityFeeSetting.current === 'dynamic' && (
				<Text.MICRO3 className="mt-1 mb-3 text-text-secondary">
					Automatically adjusts, targeting the 75th percentile of fees in the
					last 10 blocks.
				</Text.MICRO3>
			)}

			{localPriorityFeeSetting.current === 'custom' ? (
				<div className="mb-2 space-y-1">
					<Text.BODY2 className="mb-2 text-text-label">Custom Fee</Text.BODY2>
					<TextField.Default
						type="number"
						value={localCustomPriorityFee.current?.toString()}
						suffix="SOL"
						showIconForMarketSymbol="SOL"
						stepAmount={0.0001}
						highlighted={localPriorityFeeSetting.current === 'custom'}
						onChange={(value) => {
							try {
								let valueNum = +value;
								if (!isNaN(valueNum) && valueNum >= 0) {
									if (valueNum > SANITY_CHECK_ABS_MAX_FEE_IN_SOL) {
										setShowMaxFeeMessage(true);

										updateSettingsValue({
											priorityFee: 'custom',
											customPriorityFee: SANITY_CHECK_ABS_MAX_FEE_IN_SOL,
										});

										localPriorityFeeSetting.current = 'custom';
										localCustomPriorityFee.current = `${SANITY_CHECK_ABS_MAX_FEE_IN_SOL}`;
									} else {
										// manually enforce 1 lamport as minimum size since 1e-9 has issues with generic component
										const decimalPlaces = value?.split('.')?.[1]?.length ?? 0;
										if (decimalPlaces > 9) {
											value = MIN_SOL_STR;
											valueNum = +value;
										}
										updateSettingsValue({
											priorityFee: 'custom',
											customPriorityFee: valueNum,
										});

										localPriorityFeeSetting.current = 'custom';
										localCustomPriorityFee.current = value;
										setShowMaxFeeMessage(false);
									}
								} else {
									localCustomPriorityFee.current = '';
								}
							} catch (e) {
								return;
							}
						}}
					/>
				</div>
			) : (
				<div className="flex items-center justify-between w-full space-x-2">
					<LabelAndField>
						<div className="inline-flex items-center justify-between mb-1 text-text-label">
							<Text.BODY3>Est. Fee</Text.BODY3>
							<Text.BODY3 className="text-text-secondary">
								{getPriorityFeeEstimateInUsd(localPriorityFeeSetting.current)}
							</Text.BODY3>
						</div>

						<TextField.Default
							type="number"
							onChange={() => {}}
							//@ts-ignore
							value={parseFloat(
								getPriorityFeeEstimateInSol(
									localPriorityFeeSetting.current
								)?.toFixed(9)
							)}
							showIconForMarketSymbol="SOL"
							suffix={'SOL'}
							disabled
						/>
					</LabelAndField>
					<LabelAndField>
						<div className="inline-flex items-center justify-between mb-1 text-text-label">
							<Text.BODY3>Max Fee Cap</Text.BODY3>
							<Text.BODY3 className="text-text-secondary">
								{convertSolToUsdString(
									parseFloat(localPriorityFeeMaxCap.current)
								)}
							</Text.BODY3>
						</div>

						<TextField.Default
							type="number"
							onChange={(value) => {
								let valueNum = +value;

								if (!isNaN(valueNum) && valueNum >= 0) {
									if (valueNum > SANITY_CHECK_ABS_MAX_FEE_IN_SOL) {
										valueNum = SANITY_CHECK_ABS_MAX_FEE_IN_SOL;
										value = '1';
										setShowMaxFeeMessage(true);
									} else {
										setShowMaxFeeMessage(false);
									}

									// manually enforce 1 lamport as minimum size since 1e-9 has issues with generic component
									const decimalPlaces = value?.split('.')?.[1]?.length ?? 0;
									if (decimalPlaces > 9) {
										value = MIN_SOL_STR;
										valueNum = +value;
									}

									updateSettingsValue({
										maxPriorityFeeCap: valueNum,
										//priorityFee: localPriorityFeeSetting.current,
									});
									localPriorityFeeMaxCap.current = value;
								} else {
									localPriorityFeeMaxCap.current = '';
								}
							}}
							stepAmount={0.0001}
							value={localPriorityFeeMaxCap.current}
							showIconForMarketSymbol="SOL"
							suffix={'SOL'}
						/>
					</LabelAndField>
				</div>
			)}

			{showMaxFeeMessage && (
				<InfoMessage
					type="warn"
					message={`Max fee is ${SANITY_CHECK_ABS_MAX_FEE_IN_SOL} SOL`}
					className="mt-2"
				/>
			)}

			{showMaxLowerThanEstMessage && (
				<InfoMessage
					type="warn"
					message={`Max fee is less than current estimate. Transactions may time out.`}
					className="mt-2"
				/>
			)}
		</div>
	);
};

export default PriorityFeeSettingsInputs;
