'use client';

import { OrderbookHorizontal, OrderbookVertical } from '@drift-labs/icons';
import {
	BigNum,
	MarketType,
	PRICE_PRECISION_EXP,
	PublicKey,
	isVariant,
} from '@drift-labs/sdk';
import { COMMON_UI_UTILS } from '@drift/common';
import React, {
	PropsWithChildren,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import useDevSwitchIsOn from '../../hooks/useDevSwitchIsOn';
import useDriftTheme from '../../hooks/useDriftTheme';
import DevOrderbookPanel from '../Dev/DevOrderbookPanel';
import Chevron from '../Icons/Chevron';
import InlineLoadingBar from '../InlineLoadingBar/InlineLoadingBar';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import useOrderbookDisplayPreference, {
	ORDERBOOK_DISPLAY_TYPE,
} from './useOrderbookDisplayPreference';
import {
	getBucketFloorForPrice,
	getBucketForUserLiquidity,
	mergeBidsAndAsksForGroupsize,
} from './OrderbookUtils';
import { OrderBookBidAsk, OrderBookDisplayState } from './OrderbookTypes';
import { GROUPING_TYPE } from './OrderbookTypes';
import { CategorisedLiquidity } from './OrderbookTypes';
import { OrderbookDisplayProps } from './OrderbookTypes';
import { notify } from '../../utils/notifications';
import { dlog } from '../../dev';
import usePostHogCapture from '../../hooks/posthog/usePostHogCapture';
import useCurrentAuthority from '../../hooks/useCurrentAuthority';
import Link from '../Utils/Link';
import useDevStore from '../../stores/useDevStore';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import PopoverWrapper from '../PopoverWrapper';
import PoweredByOpenBookAndPhoenix from '../Utils/PoweredByOpenbookAndPhoenix';
import useInfoForCurrentlySelectedMarket from 'src/hooks/useInfoForCurrentlySelectedMarket';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import Button from '../Button';
import { MAX_PREDICTION_PRICE_NUM } from 'src/constants/math';
export * from './OrderbookTypes';

const ALERT_CROSSING_OB =
	process?.env?.NEXT_PUBLIC_ALERT_CROSSING_OB === 'true';

const getCrossingObDebugInfo = (
	authority: PublicKey,
	processedOrderbookState: OrderBookDisplayState,
	orderbookProps: OrderbookDisplayProps
) => {
	const top3ProcessedBidsAndAsks = {
		bids: processedOrderbookState.bids.slice(0, 3),
		asks: processedOrderbookState.asks.slice(0, 3),
	};

	const top3PropsBidsAndAsks = {
		bids: orderbookProps.orderbookDisplayState.bids.slice(0, 3),
		asks: orderbookProps.orderbookDisplayState.asks.slice(0, 3),
	};

	return {
		authority: authority?.toString(),
		top3ProcessedBidsAndAsks,
		top3PropsBidsAndAsks,
		depth: orderbookProps.depth,
		groupingType: orderbookProps.groupingType,
		groupingSizeSelection: orderbookProps.groupingSizeSelection,
		currentUserBidsAndAsks: orderbookProps.currentUserBidsAndAsks,
	};
};

/**
 * Notes on the FIXED "core price panel" rendering.
 *
 * Needed to create a duplicate "top" and "bottom" version of the panels, because rendering a single fixed one would jitter too much as the main panel was scrolled around. Easier to have a basic one in line in the view, and then render a fix top/bottom one OVER THE TOP if necessary.
 *
 * We do this by tracking the position of the main panel in the window. If its vertical position goes out of view then we turn a flag on to either render the fixed top or bottom ones as well
 */

// TODO - properly type this method
const hasOpenOrderForPriceGroup = (
	openOrderPrices: number[],
	price: number,
	grouping: number
) => {
	return !!openOrderPrices.find((ooPrice) => {
		return (
			ooPrice >= parseFloat(price.toString()) && ooPrice < price + grouping
		);
	});
};

// TODO - properly type this method
export function isEqual(obj1: any, obj2: any, keys?: string[]) {
	if (!keys && Object.keys(obj1).length !== Object.keys(obj2).length) {
		return false;
	}
	keys = keys || Object.keys(obj1);
	for (const k of keys) {
		if (obj1[k] !== obj2[k]) {
			// shallow comparison
			return false;
		}
	}
	return true;
}

export function usePrevious(value: any) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef<any>(null);

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

const getZeroPaddingForGroupingSize = (groupingSize: number) => {
	const precision = Math.floor(Math.log10(groupingSize));

	if (precision >= 0 || groupingSize === 0) return 0;

	return Math.abs(precision);
};

export const LiquidityBackgroundDisplay = ({
	cumulativeSize,
	totalSizePercent,
	totalCumulativeSize,
	displayType,
	side,
	marketType,
}: {
	cumulativeSize: CategorisedLiquidity;
	totalSizePercent: number;
	totalCumulativeSize: number;
	displayType: ORDERBOOK_DISPLAY_TYPE;
	side: 'buy' | 'sell';
	marketType: MarketType;
}) => {
	const currentTheme = useDriftTheme();
	const isLightTheme = currentTheme === DriftTheme.light;

	const dlobBgColor =
		side === 'buy'
			? isLightTheme
				? 'bg-green-20'
				: 'bg-green-90'
			: isLightTheme
			? 'bg-red-20'
			: 'bg-red-90';

	const vammBgColor = side === 'buy' ? 'bg-positive-green' : 'bg-negative-red';

	const serumBgColor = vammBgColor;

	const vammWidthPct =
		(cumulativeSize.vamm / totalCumulativeSize) * totalSizePercent;

	const dlobWidthPct =
		(cumulativeSize.dlob / totalCumulativeSize) * totalSizePercent;

	const serumWidthPct =
		(cumulativeSize.serum / totalCumulativeSize) * totalSizePercent;

	const phoenixWidthPct =
		(cumulativeSize.phoenix / totalCumulativeSize) * totalSizePercent;

	const invertBgPosition =
		displayType === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL && side === 'buy';

	return (
		<div
			className={`absolute opacity-20 w-full h-full flex ${
				invertBgPosition ? 'flex-row-reverse' : 'justify-start'
			}`}
		>
			{isVariant(marketType, 'perp') ? (
				<div
					className={`${vammBgColor} brightness-100 h-full`}
					style={{
						width: `${vammWidthPct}%`,
					}}
				/>
			) : (
				<div
					className={`${serumBgColor} brightness-100 h-full`}
					style={{
						width: `${serumWidthPct + phoenixWidthPct}%`,
					}}
				/>
			)}
			<div
				className={`${dlobBgColor} brightness-100 h-full`}
				style={{
					width: `${dlobWidthPct}%`,
				}}
			/>
		</div>
	);
};

const OrderbookStyleToggle = (props: { onOptionClick?: () => void }) => {
	const [displayPreference, updateDisplayPreference] =
		useOrderbookDisplayPreference();

	const selectedVertical =
		displayPreference === ORDERBOOK_DISPLAY_TYPE.VERTICAL;
	const selectedHorizontal =
		displayPreference === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL;

	return (
		<div className="flex items-center gap-2">
			<span
				onClick={() => {
					updateDisplayPreference(ORDERBOOK_DISPLAY_TYPE.VERTICAL);
					if (props.onOptionClick) {
						props.onOptionClick();
					}
				}}
				className={twMerge('flex items-center justify-center cursor-pointer')}
			>
				<OrderbookVertical
					className={selectedVertical ? undefined : 'icon-label'}
					size={24}
				/>
			</span>
			<span
				onClick={() => {
					updateDisplayPreference(ORDERBOOK_DISPLAY_TYPE.HORIZONTAL);
					if (props.onOptionClick) {
						props.onOptionClick();
					}
				}}
				className={twMerge('flex items-center justify-center cursor-pointer')}
			>
				<OrderbookHorizontal
					className={selectedHorizontal ? undefined : 'icon-label'}
					size={24}
				/>
			</span>
		</div>
	);
};

const OrderbookSectionsWrapper = forwardRef<
	HTMLDivElement,
	PropsWithChildren<{
		displayType: ORDERBOOK_DISPLAY_TYPE;
	}>
>((props, ref) => {
	return (
		<div
			ref={ref}
			className={twMerge(
				'absolute w-full max-h-full overflow-auto thin-scroll flex box-border',
				/**
				 * We render the buy side first.
				 * When Horizontal => Buy side on left
				 * When Vertical => Buy side on the bottom
				 */
				props.displayType === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL
					? 'flex-row'
					: 'flex-col-reverse'
			)}
		>
			{props.children}
		</div>
	);
});
OrderbookSectionsWrapper.displayName = 'OrderbookSectionsWrapper';

const OrderbookRowsWrapper = (
	props: PropsWithChildren<{
		displayType: ORDERBOOK_DISPLAY_TYPE;
		side: 'buy' | 'sell';
	}>
) => {
	return (
		<div
			data-puppet-tag={props.side}
			className={twMerge(
				'flex flex-col',
				props.side === 'sell' &&
					props.displayType === ORDERBOOK_DISPLAY_TYPE.VERTICAL &&
					'flex-col-reverse',
				props.displayType === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL
					? 'w-1/2'
					: 'w-full'
			)}
		>
			{props.children}
		</div>
	);
};

const OrderbookTableHeader = (
	props: PropsWithChildren<{
		displayType: ORDERBOOK_DISPLAY_TYPE;
	}>
) => {
	return (
		<div
			className={`flex justify-between text-xs px-2 py-1 text-text-tertiary`}
		>
			{props.displayType === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL ? (
				<>
					<Text.BODY3 className={`text-left`}>{'Size'}</Text.BODY3>
					<Text.BODY3 className={`text-center`}>{`${'Price'}`}</Text.BODY3>
					<Text.BODY3 className={`text-right`}>{'Size'}</Text.BODY3>
				</>
			) : (
				<>
					<Text.BODY3 className={`text-center`}>{`${'Price'}`}</Text.BODY3>
					<Text.BODY3 className={`text-left`}>{'Size'}</Text.BODY3>
				</>
			)}
		</div>
	);
};

/**
 * Renders the panel that displays the mid / most recent prices and a button to recenter the orderbook view
 */
const CorePricePanel = (props: {
	zeroPadding: number;
	latestFillPrice: BigNum;
	midPrice: BigNum;
	centerCorePricePanelInView: () => void;
	className?: string;
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const isInvertOrderbookForPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);

	const getMidPrice = () => {
		const midPrice = isInvertOrderbookForPredictionMarket
			? MAX_PREDICTION_PRICE_NUM - props.midPrice.toNum()
			: props.midPrice.toNum();

		return midPrice.toLocaleString(undefined, {
			minimumFractionDigits: props.zeroPadding,
			maximumFractionDigits: props.zeroPadding,
		});
	};

	const getLatestFillPrice = () => {
		const latestFillPrice = isInvertOrderbookForPredictionMarket
			? MAX_PREDICTION_PRICE_NUM - props.latestFillPrice.toNum()
			: props.latestFillPrice.toNum();

		return latestFillPrice.toLocaleString(undefined, {
			minimumFractionDigits: props.zeroPadding,
			maximumFractionDigits: props.zeroPadding,
		});
	};

	return (
		<div
			className={`w-full px-2 inline-flex justify-between items-center py-1 min-h-[26px] space-x-2 bg-container-bg-hover hover:cursor-pointer text-text-default ${
				props?.className ?? ''
			}`}
			onClick={props.centerCorePricePanelInView}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
			onMouseEnter={() => {
				setIsHovered(true);
			}}
		>
			<div className="flex items-center space-x-2">
				<Tooltip content={<div>Mid Price</div>}>
					<div className="flex flex-col">
						<Text.BODY2 className="text-text-emphasis">
							{getMidPrice()}
						</Text.BODY2>
					</div>
				</Tooltip>
				<Tooltip content={<div>Latest Fill Price</div>}>
					<div className="flex flex-col">
						<Text.BODY3 className="text-text-label">
							{getLatestFillPrice()}
						</Text.BODY3>
					</div>
				</Tooltip>
			</div>
			<div
				className={twMerge(
					`pb-1 transition-colors`,
					isHovered ? 'text-text-emphasis' : 'text-interactive-link'
				)}
			>
				<Text.MICRO1>{`Re-center`}</Text.MICRO1>
			</div>
		</div>
	);
};

const OrderbookDisplay = forwardRef(function OrderbookDisplay(
	props: OrderbookDisplayProps,
	ref: React.ForwardedRef<unknown>
) {
	const marketInfo = useInfoForCurrentlySelectedMarket();
	const uncrossDisabled = useDevStore((s) => s.dlobSettings.disableUncross);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);

	const isEmulatingAccount = useDriftStore((s) => s.isEmulatingAccount);

	const bidRowsLengthRef = useRef(0);
	const askRowsLengthRef = useRef(0);
	const authority = useCurrentAuthority();

	const [displayPreference] = useOrderbookDisplayPreference();

	const rowsSectionWrapperRef = useRef<HTMLDivElement>(null);
	const corePricePanelRef = useRef<HTMLDivElement>(null);

	const isFirstRender = useRef(true);

	const [showFloatingPrice, setShowFloatingPrice] = useState<
		'top' | 'bottom' | 'none'
	>('none');

	const centerCorePricePanelInView = () => {
		if (corePricePanelRef.current && rowsSectionWrapperRef.current) {
			const childRect = corePricePanelRef.current.getBoundingClientRect();
			const parentRect = rowsSectionWrapperRef.current.getBoundingClientRect();

			const childOffsetTop =
				childRect.top -
				parentRect.top +
				rowsSectionWrapperRef.current.scrollTop;
			const childHeight = childRect.height;

			const parentHeight = parentRect.height;

			const centerPosition =
				childOffsetTop - parentHeight / 2 + childHeight / 2;

			rowsSectionWrapperRef.current.scrollTo({
				top: centerPosition,
				behavior: 'instant',
			});
		}
	};

	useImperativeHandle(ref, () => {
		return {
			centerCorePricePanelInView,
		};
	});

	const [groupingSelection, updateGroupingSelection] =
		props.groupingSizeSelection;

	const groupingSizeValue = props.groupingSizeOptions[groupingSelection];

	const zeroPadding = getZeroPaddingForGroupingSize(groupingSizeValue);

	const orderBookState = props.orderbookDisplayState;

	// Base decimals displayed should be number of decimals in min step size
	const stepSize = useMarketStepSize(marketInfo?.info?.marketId);
	const baseDisplayDecimals = (`${stepSize}`.split('.')[1] ?? '').length;

	const [latestFillPrice, setLatestFillPrice] = useState(
		BigNum.zero(PRICE_PRECISION_EXP)
	);

	const openOrderPrices: number[] = [];

	const onGroupSizeChange = (groupSize: number) => {
		updateGroupingSelection(groupSize);
	};

	const devSwitchIsOn = useDevSwitchIsOn();

	const showLoadingBar =
		props.loading ||
		(!orderBookState.bids?.length && !devSwitchIsOn) ||
		!props.marketPriceState;

	// # User Bid Lookups - Used to signal the user's orders on the book
	const userBidPriceBucketLookup = useMemo(() => {
		const mergedUserBids = mergeBidsAndAsksForGroupsize(
			'bid',
			props.currentUserBidsAndAsks.bids,
			groupingSizeValue
		);

		const bucketLookup = new Map<number, OrderBookBidAsk>(
			mergedUserBids.map((bid) => {
				const priceBucket = getBucketForUserLiquidity(
					bid.price,
					groupingSizeValue,
					'bid'
				);
				return [priceBucket, bid];
			})
		);

		if (!isSellPredictionMarket) {
			return bucketLookup;
		} else {
			const invertedBucketLookup = new Map<number, OrderBookBidAsk>(
				Array.from(bucketLookup.entries()).map(([price, bid]) => {
					const bucketFloorPrice = getBucketFloorForPrice(
						MAX_PREDICTION_PRICE_NUM - price,
						groupingSizeValue
					);

					return [
						bucketFloorPrice,
						{
							...bid,
							price: bucketFloorPrice,
						},
					];
				})
			);

			return invertedBucketLookup;
		}
	}, [
		props.currentUserBidsAndAsks.bids,
		groupingSizeValue,
		isSellPredictionMarket,
	]);

	const userAskPriceBucketLookup = useMemo(() => {
		const mergedUserAsks = mergeBidsAndAsksForGroupsize(
			'ask',
			props.currentUserBidsAndAsks.asks,
			groupingSizeValue
		);

		const bucketLookup = new Map<number, OrderBookBidAsk>(
			mergedUserAsks.map((ask) => {
				const priceBucket = getBucketForUserLiquidity(
					ask.price,
					groupingSizeValue,
					'ask'
				);
				return [priceBucket, ask];
			})
		);

		if (!isSellPredictionMarket) {
			return bucketLookup;
		} else {
			const invertedBucketLookup = new Map<number, OrderBookBidAsk>(
				Array.from(bucketLookup.entries()).map(([price, ask]) => {
					const bucketFloorPrice = getBucketFloorForPrice(
						MAX_PREDICTION_PRICE_NUM - price,
						groupingSizeValue
					);

					return [
						bucketFloorPrice,
						{
							...ask,
							price: bucketFloorPrice,
						},
					];
				})
			);

			return invertedBucketLookup;
		}
	}, [
		props.currentUserBidsAndAsks.asks,
		groupingSizeValue,
		isSellPredictionMarket,
	]);

	const getUserLiquidityForPrice = (price: number, side: 'bid' | 'ask') => {
		let isCurrentUserLiquidity = false;
		let currentUserLiquiditySize = 0;

		const priceToUse = price;
		const priceBucket = getBucketFloorForPrice(priceToUse, groupingSizeValue);

		const lookupBucket = isSellPredictionMarket
			? side === 'bid'
				? userAskPriceBucketLookup
				: userBidPriceBucketLookup
			: side === 'bid'
			? userBidPriceBucketLookup
			: userAskPriceBucketLookup;

		const matchingUserBidAsk = lookupBucket.get(priceBucket);

		if (matchingUserBidAsk) {
			isCurrentUserLiquidity = true;
			currentUserLiquiditySize = matchingUserBidAsk.size;
		}

		return { isCurrentUserLiquidity, currentUserLiquiditySize };
	};

	useEffect(() => {
		if (!corePricePanelRef.current || props.loading) return;
		centerCorePricePanelInView();
	}, [displayPreference, groupingSelection, props.loading]);

	useEffect(() => {
		if (orderBookState.bids.length === 0 && orderBookState.asks.length === 0) {
			isFirstRender.current = true;
		}
		if (!corePricePanelRef.current) return;
		if (!isFirstRender.current) return;
		if (orderBookState.asks.length > 0 || orderBookState.bids.length > 0) {
			// On mobile the orderbook is being rendered in the background before it is actually visible (meaning anchor scrolling won't work), so delay the initial scroll until the element is visible too
			if (!UI_UTILS.elementIsVisible(corePricePanelRef.current)) {
				return;
			}

			isFirstRender.current = false;
			setTimeout(() => {
				centerCorePricePanelInView();
			}, 0);
		}
	}, [orderBookState.bids, orderBookState.asks]);

	useEffect(() => {
		if (props.latestMarketTrades && props.latestMarketTrades[0]) {
			setLatestFillPrice(
				COMMON_UI_UTILS.calculateAverageEntryPrice(
					props.latestMarketTrades[0].quoteAssetAmountFilled,
					props.latestMarketTrades[0].baseAssetAmountFilled
				)
			);
		}
	}, [props.latestMarketTrades]);

	useEffect(() => {
		/**
		 * The purpose of this hook is to determine which state the core price panel "anchor" is in .. it's either in the view of the scrollable window, or at the TOP or BOTTOM of it. Depending on which state it's in, we might render the fixed core price panels
		 */

		const wrapper = rowsSectionWrapperRef.current;

		function updatePosition() {
			if (displayPreference !== ORDERBOOK_DISPLAY_TYPE.VERTICAL) {
				return;
			}

			// Add requestAnimationFrame to ensure DOM updates are complete - otherwise wrapper.scrollTop = 0 on first render cycle and CorePricePanel will be rendered at the top of the orderbook too
			requestAnimationFrame(() => {
				const anchorElem = corePricePanelRef.current;
				if (!anchorElem || !wrapper) return;

				const anchorRect = anchorElem.getBoundingClientRect();
				const wrapperRect = wrapper.getBoundingClientRect();
				const anchorElementHeight = anchorElem.offsetHeight;
				const newTop = anchorRect.top - wrapperRect.top + wrapper.scrollTop;

				let anchorState: 'inView' | 'top' | 'bottom' = 'inView';

				if (newTop < wrapper.scrollTop) {
					anchorState = 'top';
				} else if (
					newTop + anchorElementHeight >
					wrapper.scrollTop + wrapper.offsetHeight
				) {
					anchorState = 'bottom';
				}

				setShowFloatingPrice(anchorState === 'inView' ? 'none' : anchorState);
			});
		}

		if (!wrapper) return;

		wrapper.addEventListener('scroll', updatePosition);
		window.addEventListener('resize', updatePosition);

		// Initial position
		updatePosition();

		return () => {
			wrapper.removeEventListener('scroll', updatePosition);
			window.removeEventListener('resize', updatePosition);
		};
	}, [
		props.orderbookDisplayState.bids.length,
		props.orderbookDisplayState.asks.length,
		groupingSelection,
		displayPreference,
		rowsSectionWrapperRef?.current,
	]);

	useEffect(() => {
		if (
			orderBookState.bids.length !== bidRowsLengthRef.current ||
			orderBookState.asks.length !== askRowsLengthRef.current
		) {
			centerCorePricePanelInView();
			bidRowsLengthRef.current = orderBookState.bids.length;
			askRowsLengthRef.current = orderBookState.asks.length;
		}
	}, [orderBookState.bids.length, orderBookState.asks.length]);

	const posthogCapture = usePostHogCapture();

	// Hook to alert with orderbook crossing warnings
	useEffect(() => {
		if (uncrossDisabled) return;
		if (!devSwitchIsOn) return;
		if (orderBookState.bids.length === 0) return;
		if (orderBookState.asks.length === 0) return;
		if (isEmulatingAccount) return; // Skip logging crossing toasts when emulating. Because we expect the orderbook to cross when looking at a market maker which is likely to be the case when emulating

		if (orderBookState.bids[0].price > orderBookState.asks[0].price) {
			if (ALERT_CROSSING_OB) {
				notify({
					type: 'warning',
					message: 'Orderbook is crossed',
					description: 'Check dev logs in console for debugging',
				});
			}

			dlog(`crossed_orderbook`, `crossed_orderbook_state`, {
				orderBookState,
				props,
			});

			const debugInfo = getCrossingObDebugInfo(
				authority,
				orderBookState,
				props
			);

			posthogCapture.captureEvent('crossed_orderbook', {
				debugInfo,
			});
		}
	}, [
		orderBookState.bids,
		orderBookState.asks,
		authority,
		uncrossDisabled,
		isEmulatingAccount,
	]);

	return (
		<div className="relative h-full bg-container-bg">
			{
				<div className="flex flex-col h-full text-text-label fadein-floating-element bg-container-bg xs:min-h-[25vh] md:min-h-0">
					{!showLoadingBar ? (
						<>
							<OrderbookTableHeader displayType={displayPreference} />
							<div
								className={`relative flex flex-col flex-grow ${
									displayPreference === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL
										? 'justify-start'
										: 'justify-center'
								}`}
							>
								{displayPreference === ORDERBOOK_DISPLAY_TYPE.VERTICAL && (
									<>
										{/* These are the FIXED top or bottom core price panel elements we render if the anchor has scrolled out of view of the main window. these use a high z-index so that the OVERLAP the "main" core price panel which is rendered inline with the other price elements */}
										<CorePricePanel
											centerCorePricePanelInView={centerCorePricePanelInView}
											className={twMerge(
												'w-full absolute top-0 z-20 right-[8px] pl-[16px]',
												showFloatingPrice === 'top' ? '' : 'hidden'
											)}
											midPrice={props.marketPriceState.markPrice}
											latestFillPrice={latestFillPrice}
											zeroPadding={zeroPadding}
										/>
										<CorePricePanel
											centerCorePricePanelInView={centerCorePricePanelInView}
											className={twMerge(
												'w-full absolute bottom-0 z-20 right-[8px] pl-[16px]',
												showFloatingPrice === 'bottom' ? '' : 'hidden'
											)}
											midPrice={props.marketPriceState.markPrice}
											latestFillPrice={latestFillPrice}
											zeroPadding={zeroPadding}
										/>
									</>
								)}
								<OrderbookSectionsWrapper
									ref={rowsSectionWrapperRef}
									displayType={displayPreference}
								>
									{displayPreference === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL && (
										<div ref={corePricePanelRef} />
									)}
									<OrderbookRowsWrapper
										side="buy"
										displayType={displayPreference}
									>
										{orderBookState?.bids.map(
											({ price, size, cumulativeSize }, index) => {
												const {
													isCurrentUserLiquidity,
													currentUserLiquiditySize,
												} = getUserLiquidityForPrice(price, 'bid');

												return (
													<OrderbookRow
														hasOpenOrder={hasOpenOrderForPriceGroup(
															openOrderPrices,
															price,
															groupingSelection
														)}
														/**
														 * Using the index for key is usually not good, but in this specific type of usage,
														 * the tooltip requires the key to stay consistent based on the position in the table,
														 * otherwise it behaves incorrectly
														 */
														key={
															displayPreference ===
															ORDERBOOK_DISPLAY_TYPE.VERTICAL
																? index
																: `${price}-${size}`
														}
														price={price}
														size={size}
														cumulativeSize={cumulativeSize}
														totalSize={orderBookState.bidTotalSize}
														marketType={props.marketId.marketType}
														side="buy"
														zeroPadding={zeroPadding}
														isCurrentUserLiquidity={isCurrentUserLiquidity}
														currentUserLiquiditySize={currentUserLiquiditySize}
														displayType={displayPreference}
														baseDisplayDecimals={baseDisplayDecimals}
													/>
												);
											}
										)}
									</OrderbookRowsWrapper>

									{displayPreference === ORDERBOOK_DISPLAY_TYPE.VERTICAL && (
										<>
											{/* This is the main core price panel, rendered in line with the other price panel elements. We use the localtion of this one to determine whether we should be rendering a fixed one at the top or bottom of the scrollable window */}
											<div className="" ref={corePricePanelRef}>
												<CorePricePanel
													centerCorePricePanelInView={
														centerCorePricePanelInView
													}
													midPrice={props.marketPriceState.markPrice}
													latestFillPrice={latestFillPrice}
													zeroPadding={zeroPadding}
												/>
											</div>
										</>
									)}
									<OrderbookRowsWrapper
										side="sell"
										displayType={displayPreference}
									>
										{orderBookState?.asks.map(
											({ price, size, cumulativeSize }, index) => {
												const {
													isCurrentUserLiquidity,
													currentUserLiquiditySize,
												} = getUserLiquidityForPrice(price, 'ask');

												return (
													<OrderbookRow
														hasOpenOrder={hasOpenOrderForPriceGroup(
															openOrderPrices,
															price,
															groupingSelection
														)}
														/**
														 * Using the index for key is usually not good, but in this specific type of usage,
														 * the tooltip requires the key to stay consistent based on the position in the table,
														 * otherwise it behaves incorrectly
														 */
														key={
															displayPreference ===
															ORDERBOOK_DISPLAY_TYPE.VERTICAL
																? index
																: `${price}-${size}`
														}
														price={price}
														size={size}
														cumulativeSize={cumulativeSize}
														totalSize={orderBookState.askTotalSize}
														marketType={props.marketId.marketType}
														side="sell"
														zeroPadding={zeroPadding}
														isCurrentUserLiquidity={isCurrentUserLiquidity}
														currentUserLiquiditySize={currentUserLiquiditySize}
														displayType={displayPreference}
														baseDisplayDecimals={baseDisplayDecimals}
													/>
												);
											}
										)}
									</OrderbookRowsWrapper>
								</OrderbookSectionsWrapper>
							</div>
							{devSwitchIsOn && <DevOrderbookPanel {...props} />}
							{marketInfo?.isDriftSpotMarket && (
								<div className="pt-1">
									<PoweredByOpenBookAndPhoenix />
								</div>
							)}
							<div className="flex items-center justify-between px-3 py-2 text-text-secondary">
								<OrderbookStyleToggle
									onOptionClick={centerCorePricePanelInView}
								/>
								<Tooltip
									content={`$ ${props.marketPriceState.spreadQuote.toFixed(6)}`}
									className="ml-3 text-xs font-display"
									slim
									placement="top"
								>
									<div>{`Spread: ${props.marketPriceState.spreadPct?.toFixed(
										2
									)}%`}</div>
								</Tooltip>
								<div className="inline-flex items-center mr-1 text-xs font-display text-darkBlue-30">
									<GroupSize
										options={props.groupingSizeOptions.map((option, index) => ({
											value: index,
											label: option.toString(),
										}))}
										type={props.groupingType}
										onChange={onGroupSizeChange}
										value={groupingSelection}
										className="relative flex flex-col items-end text-xs text-darkBlue-30"
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="flex items-center justify-center w-full h-full">
								<InlineLoadingBar />
							</div>
							{devSwitchIsOn && <DevOrderbookPanel {...props} />}
						</>
					)}
				</div>
			}
		</div>
	);
});

const OrderBookDisplayMemo = React.memo(OrderbookDisplay);

export default OrderBookDisplayMemo;

// eslint-disable-next-line react/display-name
const OrderbookRow = ({
	side,
	price,
	size,
	cumulativeSize,
	totalSize,
	marketType,
	hasOpenOrder,
	authority,
	zeroPadding = 0,
	isCurrentUserLiquidity,
	currentUserLiquiditySize: _currentUserLiquiditySize,
	displayType,
	baseDisplayDecimals,
}: {
	side: 'buy' | 'sell';
	price: number;
	size: number;
	cumulativeSize: CategorisedLiquidity;
	totalSize: number;
	marketType: MarketType;
	hasOpenOrder: boolean;
	authority?: PublicKey;
	zeroPadding?: number;
	isCurrentUserLiquidity?: boolean;
	currentUserLiquiditySize?: number;
	displayType: ORDERBOOK_DISPLAY_TYPE;
	baseDisplayDecimals: number;
}) => {
	const set = useDriftStore((s) => s.set);

	const formattedSize = UI_UTILS.toFixedLocaleString(size, baseDisplayDecimals);

	const formattedPrice = price.toLocaleString(undefined, {
		minimumFractionDigits: zeroPadding,
		maximumFractionDigits: zeroPadding,
	});

	const totalCumulativeSize =
		cumulativeSize.dlob +
		cumulativeSize.vamm +
		cumulativeSize.serum +
		cumulativeSize.phoenix;
	const totalSizePercent = totalSize
		? (totalCumulativeSize / totalSize) * 100
		: 0;

	const handlePriceClick = (price: number /*, side?: 'buy' | 'sell'*/) => {
		// Do we want to auto change the side when user clicks? I think no but am not sure
		// set((state) => {
		// 	state.tradeForm.side = side;
		// });
		// setTimeout(() => {
		set((state) => {
			if (
				state.tradeForm.orderType === 'market' ||
				state.tradeForm.orderType === 'oracleLimit'
			) {
				state.tradeForm.orderType = 'limit';
			}
			// @ts-expect-error
			state.tradeForm.priceBoxStringValue = COMMON_UI_UTILS.trimTrailingZeros(
				price.toString()
			);
		});
		// }, 0);
	};

	const handleSizeClick = (size: number) => {
		set((state) => {
			state.tradeForm.leadSide = 'base';
			// Round size to 5 decimals... avoids a bunch of zeroes in trade form
			const roundTo = 10 ** 5;
			// @ts-expect-error
			state.tradeForm.baseSizeStringValue = COMMON_UI_UTILS.trimTrailingZeros(
				(Math.round(size * roundTo) / roundTo).toString()
			);
		});
	};

	const ROW_HEIGHT = `h-6`;
	const SIZE_CLASSES = `text-xs leading-6`;

	const invertNumberPositions =
		displayType === ORDERBOOK_DISPLAY_TYPE.HORIZONTAL && side === 'buy';

	const Price = () => {
		return (
			<div
				onClick={() => handlePriceClick(price)}
				className={twMerge(
					`z-10 hover:brightness-125 hover:cursor-pointer`,
					SIZE_CLASSES,
					side === 'buy'
						? 'text-text-positive-green-button'
						: 'text-text-negative-red-button'
				)}
			>
				{authority ? (
					<Link
						className="text-gradient-1"
						lazyHref={() => '/?authority=' + authority} // Do this instead of href to avoid the expensive pubkey.toString until necessary
					>
						{formattedPrice}
					</Link>
				) : (
					formattedPrice
				)}
			</div>
		);
	};

	const Size = () => {
		const userOrderDot = (
			<div className={`w-1.5 h-1.5 mt-1/2 rounded-full bg-static-default`} />
		);

		const dotBeforeSize =
			displayType === ORDERBOOK_DISPLAY_TYPE.VERTICAL || side === 'sell';

		return (
			<div
				className={`z-10 ${SIZE_CLASSES} ${
					hasOpenOrder ? 'text-gradient-1' : 'text-static-default'
				} hover:brightness-125 hover:cursor-pointer items-center inline-flex`}
				onClick={() => handleSizeClick(totalCumulativeSize)}
			>
				{isCurrentUserLiquidity && dotBeforeSize && (
					<div className={`pr-0.5`}>{userOrderDot}</div>
				)}

				{formattedSize}

				{isCurrentUserLiquidity && !dotBeforeSize && (
					<div className={`pl-0.5`}>{userOrderDot}</div>
				)}
			</div>
		);
	};

	const OrderbookRowContent = (
		<>
			<div
				className={`w-full ${ROW_HEIGHT} flex relative box-border text-xs leading-7 justify-between font-display ${
					side === 'buy' ? `ml-0` : `mr-0`
				}`}
			>
				<div
					className={`flex ${
						invertNumberPositions ? 'flex-row-reverse' : 'flex-row'
					} mx-2 justify-between w-full`}
				>
					<Price />

					<Size />
				</div>
				<LiquidityBackgroundDisplay
					cumulativeSize={cumulativeSize}
					totalSizePercent={totalSizePercent}
					totalCumulativeSize={totalCumulativeSize}
					displayType={displayType}
					side={side}
					marketType={marketType}
				/>
			</div>
		</>
	);

	return <div className="relative w-full mb-[1px]">{OrderbookRowContent}</div>;
};

// eslint-disable-next-line react/prop-types
const GroupSize = ({
	options,
	type,
	value,
	onChange,
}: {
	options: {
		value: OrderbookDisplayProps['groupingSizeOptions'][0];
		label: string;
	}[];
	type: OrderbookDisplayProps['groupingType'];
	value: number;
	onChange: (val: number) => void;
	className?: string;
}) => {
	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			placement: 'top',
		},
		{
			disableAutoPlacement: true,
		}
	);

	return (
		<div
			className={`rounded px-1 relative`}
			ref={refs.setReference}
			{...getReferenceProps()}
		>
			<Button.Secondary
				className={`flex items-center gap-1 text-text-secondary rounded-sm space-x-0 border-none h-auto`}
				onClick={() => setIsPopoverOpen(true)}
				size="SMALL"
			>
				<Text.BODY2>
					{type === GROUPING_TYPE.PCT ? '%' : '$'}
					{options[value].label}
				</Text.BODY2>

				<Chevron
					direction={isPopoverOpen ? 'up' : 'down'}
					className={`h-5 w-5`}
				/>
			</Button.Secondary>
			{isPopoverOpen && (
				<PopoverWrapper
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
				>
					<div
						className={`z-40 p-1 bottom-full right-0 bg-container-bg origin-top-left divide-y divide-darkBlue-70 shadow-lg outline-none rounded-md overflow-auto`}
					>
						{options.map((option) => (
							<div
								key={option.label}
								className={`pl-6 p-1 text-xs text-right hover:bg-darkBlue-80 hover:cursor-pointer tracking-wider ${
									option.value === value && `text-gradient-1`
								}`}
								onClick={() => {
									onChange(option.value);
									setIsPopoverOpen(false);
								}}
							>
								{type === GROUPING_TYPE.PCT ? '%' : '$'}
								{option.label}
							</div>
						))}
					</div>
				</PopoverWrapper>
			)}
		</div>
	);
};
