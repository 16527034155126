'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import React, { memo, PropsWithChildren } from 'react';
import { Info, Wallet } from '@drift-labs/icons';
import TextField from 'src/components/Inputs/TextField';
import Text from 'src/components/Text/Text';
import Env from 'src/environmentVariables/EnvironmentVariables';
import TradeFormInputLabel from './TradeFormInputLabel';
import Tooltip from '../Tooltip/Tooltip';
import { COMMON_UI_UTILS, UIOrderType } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import useL2StateForMarket from 'src/hooks/useL2StateForMarket';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useCurrentMarketMarkPrice from 'src/hooks/useCurrentMarketMarkPrice';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { PriceChangePercentDisplay } from '../PerpPageStats/PriceChangePercentDisplay';
import NumLib from 'src/utils/NumLib';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import UI_UTILS from 'src/utils/uiUtils';
import { LiquidityBackgroundDisplay } from '../Orderbook/OrderbookDisplay';
import { ORDERBOOK_DISPLAY_TYPE } from '../Orderbook/useOrderbookDisplayPreference';
import { MAX_PREDICTION_PRICE_BIGNUM } from 'src/constants/math';
import Button from 'src/components/Button';
import { TradeFormState } from 'src/@types/trade';
import UnderlinedTabs from '../TabbedNavigation/UnderlinedTabs';
import GradientText from '../Utils/GradientText';
import RoundedGradientBorderBox from '../RoundedGradientBorderBox';
import NumberDisplayV3 from '../Utils/NumberDisplayV3';

export const postOnlyTooltip =
	"Post Only: Enforces that order is a maker order that can provide liquidity to the pool for a rebate. Without this flag, orders that don't execute immediate are not post only.";
export const reduceOnlyTooltip =
	'Reduce Only: Enforces that order will never increase or flip the current position.';
const _iocTooltip =
	'Immediate or cancel (IOC): An order is placed and potentially partially filled. The remainder that is not filled immediately is then cancelled.';
export const postOnlyAndReduceOnlyNotAllowedTooltip = `You can't use Reduce Only and Post Only on the same order.`;

export const FieldSplitter = (props: PropsWithChildren<any>) => {
	return (
		<div className="flex items-end justify-between space-x-2">
			{props.children}
		</div>
	);
};

export const LabelAndField = (props: PropsWithChildren<any>) => {
	return (
		<div
			className={twMerge('flex flex-col', props.customWidthClass ?? 'w-1/2')}
		>
			{props.children}
		</div>
	);
};

export interface OrderTypeSelectorProps {
	orderType: string;
	orderTypeOptions: any;
	onOrderTypeChange: (newValue: any) => void;
}

export const OrderTypeSelector = memo(function OrderTypeSelectorMemo({
	orderType,
	orderTypeOptions,
	onOrderTypeChange,
}: OrderTypeSelectorProps) {
	const dropdownOptions = orderTypeOptions.slice(2).map((option: any) => {
		return { ...option, onClick: () => onOrderTypeChange(option.value) };
	});

	const isMarketOrLimit = orderType === 'market' || orderType === 'limit';
	const selectedOrderTypeOption = orderTypeOptions.find(
		(option: any) => option.value === orderType
	);

	const tabs = [
		orderTypeOptions[0],
		orderTypeOptions[1],
		{
			...(isMarketOrLimit ? orderTypeOptions[2] : selectedOrderTypeOption),
			dropdownOptions,
		},
	];

	return (
		<div className="flex items-center justify-between w-full">
			<div className="flex flex-col justify-start">
				<UnderlinedTabs
					className="whitespace-nowrap"
					options={tabs}
					currentSelection={orderType}
					onChange={onOrderTypeChange}
					tabClassName={
						'grow text-center text-[14px] leading-[16px] items-center justify-center'
					}
					gradient
				/>
			</div>
			<div className="flex flex-col justify-end">
				{selectedOrderTypeOption?.description && (
					<Tooltip content={selectedOrderTypeOption.description}>
						<Info
							className="mb-[5px] ml-0.5"
							size={18}
							color={'var(--text-label)'}
						/>
					</Tooltip>
				)}
			</div>
		</div>
	);
});

interface PriceBoxProps {
	priceBoxHeading: string;
	priceBoxStringValue: string;
	stepAmount: number;
	onPriceBoxChange: (value: string) => void;
	onPriceBoxSave?: (newValue: any) => void;
	priceBoxDisabledPlaceholder?: false | string;
	className?: string;
}

export const PriceBox = memo(function PriceBoxMemo({
	priceBoxHeading,
	priceBoxStringValue,
	stepAmount,
	onPriceBoxChange,
	onPriceBoxSave,
	priceBoxDisabledPlaceholder,
	className,
}: PriceBoxProps) {
	return (
		<div
			className={twMerge('flex justify-between space-x-2 w-full', className)}
		>
			<div className="flex flex-col" style={{ flexGrow: 2 }}>
				<TradeFormInputLabel>{priceBoxHeading}</TradeFormInputLabel>
				{priceBoxDisabledPlaceholder ? (
					<div className="pointer-events-none bg-input-bg rounded-sm w-full px-2 flex items-center text-text-default h-[32px] select-none">
						{priceBoxDisabledPlaceholder}
					</div>
				) : (
					<TextField.Default
						value={priceBoxStringValue}
						disabled={false}
						type="number"
						stepAmount={stepAmount}
						onChange={onPriceBoxChange}
						suffix="USD"
						onBlur={() => onPriceBoxSave?.(priceBoxStringValue)}
					/>
				)}
			</div>
		</div>
	);
});

export interface SizeSelectorProps {
	reduceOnly: boolean;
	baseSizeStringValue: string;
	formattedMarketSymbol: string;
	quoteSizeStringValue: string;
	maxQuoteAvailable: number;
	maxBaseAvailableString: string;
	currentPositionBazeSizeString: string;
	onBaseSizeChange: (value: string) => void;
	handleQuoteSizeChange: (value: string) => void;
	showMaxButton: boolean;
	stepSize: number;
	orderType: UIOrderType;
	userHasSelfImposedMax?: boolean;
	isInDepositAndTradeState: boolean;
	baseSizeInputId?: string;
}

const MaxSizeButton = ({
	currentPositionBazeSizeString,
	maxQuoteAvailable,
	formattedMarketSymbol,
	userHasSelfImposedMax,
	reduceOnly,
	onBaseSizeChange,
	handleQuoteSizeChange,
	isUsingMaxSize,
}: Pick<
	SizeSelectorProps,
	| 'currentPositionBazeSizeString'
	| 'maxQuoteAvailable'
	| 'formattedMarketSymbol'
	| 'userHasSelfImposedMax'
	| 'reduceOnly'
	| 'onBaseSizeChange'
	| 'handleQuoteSizeChange'
> & {
	isUsingMaxSize?: boolean;
}) => {
	const TextWrapper = isUsingMaxSize ? GradientText : 'span';

	return (
		<TradeFormInputLabel allowPointer className={'flex items-end'}>
			<RoundedGradientBorderBox
				className="flex items-end space-x-2 hover:cursor-pointer text-text-emphasis"
				onClick={() => {
					reduceOnly
						? onBaseSizeChange(currentPositionBazeSizeString)
						: handleQuoteSizeChange(maxQuoteAvailable.toFixed(2));
				}}
				borderWidth="1px"
				borderRadius="0.25rem"
				borderColour={
					isUsingMaxSize ? 'var(--app-gradient)' : 'var(--button-secondary-bg)'
				}
			>
				<div
					className={`h-full flex items-center font-numeral bg-button-secondary-bg hover:bg-button-secondary-bg-hover hover:cursor-pointer px-1 pt-0.5 pb-0 rounded-sm select-none text-text-label tracking-normal`}
					style={{ fontSize: '10px' }}
				>
					<TextWrapper>
						<span className="mr-1">
							{reduceOnly ? 'Position' : 'Max'}
							{': '}
						</span>
						<span>
							{reduceOnly
								? currentPositionBazeSizeString
								: Math.max(maxQuoteAvailable, 0).toLocaleString(Env.locale, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
								  })}
							{reduceOnly ? ` ${formattedMarketSymbol}` : ` USD`}
						</span>
					</TextWrapper>
				</div>
			</RoundedGradientBorderBox>
			{userHasSelfImposedMax && (
				<Tooltip
					content={`Your max amount shown here is lower than the protocol allows due to self imposed max account leverage. You can adjust this in settings.`}
				>
					<Info className="mb-[5px] ml-0.5" size={14} />
				</Tooltip>
			)}
		</TradeFormInputLabel>
	);
};

export const PerpTradeFormPrimaryButton = ({
	connected,
	side,
	reduceOnly,
	tradeFormState,
	tradeFormStoreState,
	currentMarketSymbol,
	baseSizeStringValue,
	stepSize,
	showTradeConfirmation,
	hasAgreedToPriceDivergence,
	onSubmit,
	isGeoblocked,
	isSendingTransaction,
	leadSide,
	rawBaseValue,
	isInDepositAndTradeState,
}: {
	connected: boolean;
	reduceOnly: boolean;
	side: 'buy' | 'sell';
	tradeFormState: TradeFormState;
	tradeFormStoreState: DriftStore['tradeForm'];
	currentMarketSymbol: string;
	baseSizeStringValue: string;
	stepSize: number;
	showTradeConfirmation: boolean;
	hasAgreedToPriceDivergence: boolean;
	onSubmit: () => void;
	isGeoblocked: boolean;
	isSendingTransaction: boolean;
	leadSide: string;
	rawBaseValue: BigNum;
	isInDepositAndTradeState: boolean;
}) => {
	const label = isInDepositAndTradeState
		? `Deposit & ${side === 'buy' ? 'Long' : 'Short'}`
		: `${side === 'buy' ? 'LONG' : 'SHORT'} ${
				rawBaseValue.gt(ZERO)
					? `${
							leadSide != 'base' ? `~` : ``
					  }${UI_UTILS.roundToStepSizeIfLargeEnough(
							baseSizeStringValue,
							stepSize
					  )}`
					: ''
		  } ${currentMarketSymbol}`;

	return isInDepositAndTradeState || connected ? (
		<Button.BigSemantic
			positive={side === 'buy'}
			disabled={
				tradeFormState.tradeButtonIsDisabled ||
				tradeFormStoreState.bracketOrders?.anyInvalid ||
				(tradeFormState.showPriceEstimateOracleDivergenceWarning &&
					!showTradeConfirmation &&
					!hasAgreedToPriceDivergence) ||
				(isGeoblocked && !reduceOnly) ||
				isSendingTransaction
			}
			onClick={onSubmit}
		>
			{tradeFormState.marketInSettlement ? (
				<Text.H3
					className={`flex items-center justify-center whitespace-normal`}
				>
					{`${currentMarketSymbol} IN SETTLEMENT`}
				</Text.H3>
			) : (
				<Text.H3
					className={`flex items-center justify-center whitespace-normal`}
				>
					{label}
				</Text.H3>
			)}
		</Button.BigSemantic>
	) : (
		<Button.Secondary size="LARGE" onClick={onSubmit} disabled={isGeoblocked}>
			{isGeoblocked ? (
				'Unavailable'
			) : (
				<>
					<Wallet className="mr-1" color="var(--text-default)" size={20} />
					<span className="mt-0.5"> Connect Wallet </span>
				</>
			)}
		</Button.Secondary>
	);
};

export const SizeSelector = memo(function SizeSelectorMemo({
	reduceOnly,
	baseSizeStringValue,
	formattedMarketSymbol,
	quoteSizeStringValue,
	maxQuoteAvailable,
	maxBaseAvailableString,
	currentPositionBazeSizeString,
	onBaseSizeChange,
	handleQuoteSizeChange,
	showMaxButton,
	stepSize,
	orderType,
	userHasSelfImposedMax,
	isInDepositAndTradeState,
	baseSizeInputId,
}: SizeSelectorProps) {
	const isOracleLimitOrder = orderType === 'oracleLimit';

	const disabled = isInDepositAndTradeState ? false : maxQuoteAvailable <= 0;
	const showMaxButton_ = !isInDepositAndTradeState && showMaxButton; // TODO : Should handle this in tradeform state if "showMaxButton" is defined there

	// TODO DPE-2566 : consolidate the logic for determining if we're using max leverage
	const isUsingMaxLeverage =
		Math.abs(1 - Number(baseSizeStringValue) / Number(maxBaseAvailableString)) <
		0.01;

	return (
		<>
			<FieldSplitter>
				<LabelAndField
					customWidthClass={isOracleLimitOrder ? 'w-full' : undefined}
				>
					<Text.BODY3>
						<div className={'flex justify-between text-text-tertiary'}>
							<div className="flex pb-0.5">Size</div>
							{isOracleLimitOrder && (
								<div className="flex items-center justify-end">
									<div
										className="flex items-end space-x-2 hover:cursor-pointer text-text-emphasis"
										onClick={() => {
											onBaseSizeChange(
												reduceOnly
													? currentPositionBazeSizeString
													: maxBaseAvailableString
											);
										}}
									>
										<div
											className={`flex items-center font-numeral bg-button-secondary-bg hover:bg-button-secondary-bg-hover hover:cursor-pointer px-1 pt-0.5 pb-0 mb-1 rounded-sm select-none text-text-label tracking-normal`}
											style={{ fontSize: '10px' }}
										>
											<div className="mr-2">
												{reduceOnly ? 'Position:' : 'Max:'}
											</div>
											<span className="mr-1">
												{reduceOnly
													? currentPositionBazeSizeString
													: maxBaseAvailableString}
											</span>
											<span style={{ fontSize: '10px' }}>
												{formattedMarketSymbol}
											</span>
										</div>
									</div>
									{userHasSelfImposedMax && (
										<Tooltip
											content={`Your max amount shown here is lower than the protocol allows due to self imposed max account leverage. You can adjust this in settings.`}
										>
											<Info className="mb-[5px] ml-0.5" size={14} />
										</Tooltip>
									)}
								</div>
							)}
						</div>
					</Text.BODY3>

					<TextField.Default
						type="number"
						onChange={onBaseSizeChange}
						value={baseSizeStringValue}
						showIconForMarketSymbol={formattedMarketSymbol}
						disabled={disabled}
						stepSize={stepSize}
						depsForOnChange={[stepSize]}
						id={baseSizeInputId}
					/>
				</LabelAndField>

				{!isOracleLimitOrder && (
					<LabelAndField>
						<div className="h-[18px] mb-1">
							{showMaxButton_ && (
								<MaxSizeButton
									currentPositionBazeSizeString={currentPositionBazeSizeString}
									maxQuoteAvailable={maxQuoteAvailable}
									formattedMarketSymbol={formattedMarketSymbol}
									userHasSelfImposedMax={userHasSelfImposedMax}
									reduceOnly={reduceOnly}
									onBaseSizeChange={onBaseSizeChange}
									handleQuoteSizeChange={handleQuoteSizeChange}
									isUsingMaxSize={isUsingMaxLeverage}
								/>
							)}
						</div>

						<TextField.Default
							type="number"
							onChange={handleQuoteSizeChange}
							onBlur={() =>
								handleQuoteSizeChange(
									BigNum.fromPrint(
										quoteSizeStringValue,
										QUOTE_PRECISION_EXP
									).toFixed(2)
								)
							}
							value={isOracleLimitOrder ? '' : quoteSizeStringValue}
							showIconForMarketSymbol={'USDC'}
							disabled={isOracleLimitOrder || maxQuoteAvailable <= 0}
						/>
					</LabelAndField>
				)}
			</FieldSplitter>
		</>
	);
});

// Displays mark price, pct change, and bid/ask spread
export const MarkPriceDisplay = memo(function MarkPriceDisplayMemo() {
	const market = useDriftStore((s) => s.selectedMarket.current);
	const marketId = useDriftStore((s) => s.selectedMarket.marketId);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);

	const spotMarketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.spotLookup[market?.marketIndex]
	);
	const perpMarketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.perpLookup[market?.marketIndex]
	);

	const l2State = useL2StateForMarket(market?.marketIndex, market?.marketType);

	const ask = l2State?.asks[0];
	const bid = l2State?.bids[0];

	const markPrice = useCurrentMarketMarkPrice();
	const displayMarkPrice = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(markPrice)
		: markPrice;

	const markPriceLoading = !displayMarkPrice || displayMarkPrice.eqZero();

	const bidSize = isSellPredictionMarket
		? BigNum.from(ask?.size || ZERO, BASE_PRECISION_EXP)
		: BigNum.from(bid?.size || ZERO, BASE_PRECISION_EXP);
	const askSize = isSellPredictionMarket
		? BigNum.from(bid?.size || ZERO, BASE_PRECISION_EXP)
		: BigNum.from(ask?.size || ZERO, BASE_PRECISION_EXP);

	const bidPrice = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(
				BigNum.from(ask?.price || ZERO, PRICE_PRECISION_EXP)
		  )
		: BigNum.from(bid?.price || ZERO, PRICE_PRECISION_EXP);
	const askPrice = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(
				BigNum.from(bid?.price || ZERO, PRICE_PRECISION_EXP)
		  )
		: BigNum.from(ask?.price || ZERO, PRICE_PRECISION_EXP);

	const askCumulativeSize = {
		vamm: BigNum.from(ask?.sources?.vamm || ZERO, BASE_PRECISION_EXP).toNum(),
		dlob: BigNum.from(ask?.sources?.dlob || ZERO, BASE_PRECISION_EXP).toNum(),
		serum: BigNum.from(ask?.sources?.serum || ZERO, BASE_PRECISION_EXP).toNum(),
		phoenix: BigNum.from(
			ask?.sources?.phoenix || ZERO,
			BASE_PRECISION_EXP
		).toNum(),
		openbook: BigNum.from(
			ask?.sources?.openbook || ZERO,
			BASE_PRECISION_EXP
		).toNum(),
	};
	const askTotalCumulativeSize = Object.values(askCumulativeSize).reduce(
		(acc, val) => acc + val,
		0
	);

	const bidCumulativeAskSize = {
		vamm: BigNum.from(bid?.sources?.vamm || ZERO, BASE_PRECISION_EXP).toNum(),
		dlob: BigNum.from(bid?.sources?.dlob || ZERO, BASE_PRECISION_EXP).toNum(),
		serum: BigNum.from(bid?.sources?.serum || ZERO, BASE_PRECISION_EXP).toNum(),
		phoenix: BigNum.from(
			bid?.sources?.phoenix || ZERO,
			BASE_PRECISION_EXP
		).toNum(),
		openbook: BigNum.from(
			bid?.sources?.openbook || ZERO,
			BASE_PRECISION_EXP
		).toNum(),
	};
	const bidTotalCumulativeSize = Object.values(bidCumulativeAskSize).reduce(
		(acc, val) => acc + val,
		0
	);

	const askCumulativeSizeToUse = isSellPredictionMarket
		? bidCumulativeAskSize
		: askCumulativeSize;
	const askTotalCumulativeSizeToUse = isSellPredictionMarket
		? bidTotalCumulativeSize
		: askTotalCumulativeSize;
	const bidCumulativeAskSizeToUse = isSellPredictionMarket
		? askCumulativeSize
		: bidCumulativeAskSize;
	const bidTotalCumulativeSizeToUse = isSellPredictionMarket
		? askTotalCumulativeSize
		: bidTotalCumulativeSize;

	const spreadPct = (1 - bidPrice.toNum() / askPrice.toNum()) * 100;
	const spreadPctDisplay =
		spreadPct < 0.01
			? '<0.01'
			: COMMON_UI_UTILS.trimTrailingZeros(spreadPct.toFixed(2));

	const tickSizeNum = BigNum.from(
		market.isSpot
			? spotMarketInfo?.account?.orderTickSize ?? 0
			: perpMarketInfo?.account?.amm?.orderTickSize ?? 0,
		PRICE_PRECISION_EXP
	).toNum();

	const formattedBidSize =
		bidSize && bidPrice
			? NumLib.formatNum.toBaseDisplay(bidSize.toNum(), bidPrice.toNum())
			: '0';

	const formattedAskSize =
		askSize && askPrice
			? NumLib.formatNum.toBaseDisplay(askSize.toNum(), askPrice.toNum())
			: '0';

	const askPriceDisplay =
		askPrice &&
		UI_UTILS.roundToStepSizeIfLargeEnough(`${askPrice.toNum()}`, tickSizeNum);

	const bidPriceDisplay =
		bidPrice &&
		UI_UTILS.roundToStepSizeIfLargeEnough(`${bidPrice.toNum()}`, tickSizeNum);

	const cumulativeSizeBoth = bidSize.toNum() + askSize.toNum();
	const bidTotalBidSizePercent = (bidSize.toNum() / cumulativeSizeBoth) * 100;
	const askTotalSizePercent = (askSize.toNum() / cumulativeSizeBoth) * 100;

	// if vammSize is greater than 0 but less than the total bid/ask size, show the vamm portion with more opacity

	return (
		<div className="flex flex-row items-center pt-2 pb-5">
			{/* Mark price column */}
			<div className="flex flex-col w-[50%]">
				{markPriceLoading ? (
					<SkeletonValuePlaceholder className="w-16 h-4" />
				) : (
					<div className="flex flex-row items-center">
						<Text.H3>
							<NumberDisplayV3
								formattingProps={{
									displayType: 'price',
								}}
								value={displayMarkPrice}
							/>
						</Text.H3>
						<Tooltip
							className={``}
							content={
								<div className="text-xs">
									The current Mark Price for the market ({market?.marketName}
									). Mark Price is the average of the bid/ask price.{' '}
								</div>
							}
						>
							<Info className="ml-2" color="var(--text-label)" size={16} />
						</Tooltip>
					</div>
				)}
				<div className="flex flex-row items-center space-x-3">
					<PriceChangePercentDisplay
						markPrice={displayMarkPrice}
						marketId={marketId}
						isSellPredictionMarket={isSellPredictionMarket}
					/>
					<Text.BODY2 className="text-text-label">
						Spread: {spreadPctDisplay}%
					</Text.BODY2>
				</div>
			</div>

			{/* Percent change */}

			{/* bid/ask rows */}
			<div className="w-[50%]">
				<div className="relative w-full h-5">
					<div className="flex flex-row items-center justify-between w-full">
						<div className="relative z-10 flex flex-row items-center justify-between w-full px-1">
							<Text.BODY2>{askPriceDisplay}</Text.BODY2>
							<Text.BODY2 className="text-text-label">
								{formattedAskSize}
							</Text.BODY2>
						</div>

						<LiquidityBackgroundDisplay
							cumulativeSize={askCumulativeSizeToUse}
							totalSizePercent={askTotalSizePercent}
							totalCumulativeSize={askTotalCumulativeSizeToUse}
							displayType={ORDERBOOK_DISPLAY_TYPE.VERTICAL}
							side={'sell'}
							marketType={market?.marketType}
						/>
					</div>
				</div>
				<div className="relative w-full h-5">
					<div className="flex flex-row items-center justify-between w-full">
						<div className="relative z-10 flex flex-row items-center justify-between w-full px-1">
							<Text.BODY2>{bidPriceDisplay}</Text.BODY2>
							<Text.BODY2 className="text-text-label">
								{formattedBidSize}
							</Text.BODY2>
						</div>
						<LiquidityBackgroundDisplay
							cumulativeSize={bidCumulativeAskSizeToUse}
							totalSizePercent={bidTotalBidSizePercent}
							totalCumulativeSize={bidTotalCumulativeSizeToUse}
							displayType={ORDERBOOK_DISPLAY_TYPE.VERTICAL}
							side={'buy'}
							marketType={market?.marketType}
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export const ValuesSelector = <T extends number | string>({
	onSelect,
	selected,
	options,
	disabled,
}: {
	onSelect: (value: T) => void;
	selected: T | undefined | null;
	options: { value: T; title: string }[];
	disabled?: boolean;
}) => {
	return (
		<div className="flex justify-between flex-grow gap-1">
			{options.map((option) => (
				<Button.Secondary
					key={option.value}
					size="SMALL"
					roundedGradientBorder
					selected={option.value === selected}
					className="flex-grow px-2 py-2 text-xs font-numeral"
					style={{ width: '0' }}
					onClick={() => {
						onSelect(option.value);
					}}
					disabled={disabled}
				>
					{option.title}
				</Button.Secondary>
			))}
		</div>
	);
};
