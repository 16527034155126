'use client';

import { useContext } from 'react';
import { SlotContext } from 'src/providers/currentSlotProvider';
import { MarketId } from '@drift/common';
import useMarketStateStore from 'src/stores/useMarketStateStore';

export const useTargetMarketSlotStaleness = (marketId: MarketId) => {
	const marketState = useMarketStateStore()?.getMarketDataForMarket?.(marketId);

	const l2Slot = marketState?.derivedState?.updateSlot ?? 0;
	const oracleSlot = marketState?.oracle?.slot.toNumber() ?? 0;
	const marketSlot = marketState?.marketSlot ?? 0;
	const latestDlobSlot = Math.max(l2Slot, oracleSlot, marketSlot);
	const currentSlotContext = useContext(SlotContext);

	return currentSlotContext.currentSlot - latestDlobSlot;
};
