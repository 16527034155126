'use client';

import {
	EnvironmentConstants,
	RpcEndpoint,
	getResponseTimes,
} from '@drift/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import useCurrentSettings from './useCurrentSettings';
import useIsMainnet from './useIsMainnet';
import UI_UTILS from 'src/utils/uiUtils';

const average = (arr: number[]) => arr.reduce((p, c) => p + c, 0) / arr.length;

const REFRESH_RATE_SECONDS = 5;

const useRpcLatencies = (rpcOptions: RpcEndpoint[]) => {
	const isLocalHost =
		UI_UTILS.isWindowDefined() && window.location.href.includes('localhost');

	const initialState = rpcOptions.reduce<
		Record<string, { avg: number | null; lastFiveLatencies: number[] }>
	>(
		(acc, rpc) => ({
			...acc,
			[rpc.value]: {
				avg: null,
				lastFiveLatencies: [],
			},
		}),
		{} as Record<string, { avg: number | null; lastFiveLatencies: number[] }>
	);
	const [avgLatencies, setAvgLatencies] =
		useState<Record<string, { avg: number; lastFiveLatencies: number[] }>>(
			initialState
		);

	const intervalIsRunning = useRef(false);

	useEffect(() => {
		const getAndSetLatencies = async () => {
			try {
				const responseTimes = await getResponseTimes(rpcOptions);

				if (responseTimes) {
					const newLatencies: Record<
						string,
						{ avg: number; lastFiveLatencies: number[] }
					> = {};
					responseTimes.forEach((rpc) => {
						const lastFiveLatencies = !rpc.latency
							? []
							: avgLatencies?.[rpc.value]?.lastFiveLatencies
							? [
									rpc.latency,
									...avgLatencies?.[rpc.value]?.lastFiveLatencies,
							  ].slice(0, 5)
							: [rpc.latency];
						const avg = Math.ceil(average(lastFiveLatencies));
						newLatencies[rpc.value] = { avg, lastFiveLatencies };
					});
					setAvgLatencies(newLatencies);
				}
			} catch (err) {
				console.error(err);
			}
		};

		if (!intervalIsRunning.current) getAndSetLatencies();

		const interval = setInterval(
			getAndSetLatencies,
			(isLocalHost ? 10 : REFRESH_RATE_SECONDS) * 1000
		);

		intervalIsRunning.current = true;

		return () => clearInterval(interval);
	}, [avgLatencies, rpcOptions]);

	return avgLatencies;
};

const useAllRpcLatencies_ = () => {
	const isMainnet = useIsMainnet();

	const [settings] = useCurrentSettings();

	const currentRpc = settings.rpcEndpoint as RpcEndpoint;

	const rpcOptions = isMainnet
		? EnvironmentConstants.rpcs.mainnet
		: EnvironmentConstants.rpcs.dev;

	if (
		currentRpc &&
		!rpcOptions.map((opt) => opt.value).includes(currentRpc.value)
	) {
		rpcOptions.push(currentRpc);
	}

	const latencies = useRpcLatencies(rpcOptions);

	return latencies;
};

export const useAllRpcLatencies = singletonHook({}, useAllRpcLatencies_);

export const useCurrentRpcLatency = () => {
	const [settings] = useCurrentSettings();

	const currentRpc = settings.rpcEndpoint;

	const currentRpcSpec = useMemo(() => {
		const rpcSpec: RpcEndpoint = {
			...currentRpc,
			allowAdditionalConnection: false,
		};

		return rpcSpec;
	}, [currentRpc?.value]);

	const latencies = useRpcLatencies([currentRpcSpec]);

	return currentRpcSpec
		? latencies[currentRpcSpec.value]
		: { avg: -1, lastFiveLatencies: [] };
};

// export const useCurrentRpcLatency = singletonHook(
// 	{ avg: -1, lastFiveLatencies: [] },
// 	useCurrentRpcLatency_
// );
